export abstract class GlobalConstants {
    static readonly creditCard: { months: string[], years: { [year: string]: number } } = {
        months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
        years: { "2018": 18, "2019": 19, "2020": 20, "2021": 21, "2022": 22, "2023": 23, "2024": 24, "2025": 25, "2026": 26, "2027": 27, "2028": 28, "2029": 29, "2030": 30, "2031": 31, "2032": 32, "2033": 33, "2034": 34, "2035": 35, "2036": 36, "2037": 37, "2038": 38 }
    }
    static readonly MPGS: { [key: string]: string | number } = {
        "BASE_URL": "https://ap-gateway.mastercard.com/",
        "SANDBOX_MODULE_VERSION": 80,
        "SANDBOX_MODULE_MPGS_VERSION": 78
    };
}