import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


@Injectable()
export class SnackbarService {

  defaultConfig: MatSnackBarConfig = {
    duration: 4000,
    horizontalPosition: 'end',
    verticalPosition: 'top',
  }

  constructor(private snackbar: MatSnackBar) { }

  default(message: string, action: string = '', config: any = this.defaultConfig) {
    this.snackbar.open(message, action, config);
  }

  error(message: string) {
    this.snackbar.open(message, undefined, {
      ...this.defaultConfig,
      panelClass: ['snack', 'error-snack']
    });
  }

  success(message: string) {
    this.snackbar.open(message, undefined, {
      ...this.defaultConfig,
      panelClass: ['snack', 'success-snack']
    });
  }

}
