import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failure-payment',
  templateUrl: './failure-payment.component.html',
  styleUrls: ['./failure-payment.component.scss']
})
export class FailurePaymentComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
