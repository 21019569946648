import { Component, ElementRef, Inject, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { SafeHtml, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WINDOW } from '@ng-web-apis/common';

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentComponent implements OnInit {
  popupUiCode: SafeHtml = '';
  iframeSrc: SafeResourceUrl;
  iframeSrcdoc:SafeHtml;
  url:SafeResourceUrl;
  urlContent:string;
  showDocxPreviewMsg:boolean = false;
  @ViewChild('iframe') iframe: ElementRef;
  iframeWidth: string;
  iframeHeight: string;
  redirectUrl:any;
  constructor(
  @Inject(MAT_DIALOG_DATA) public htmlContent: string,
  public dialogRef: MatDialogRef<ErrorDialogComponent>,
  private sanitizer: DomSanitizer,
  @Inject(WINDOW) readonly windowRef: Window,
  ) {
    console.log(this.htmlContent);
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`data:text/html;charset=utf-8,${encodeURIComponent(this.htmlContent)}`);  
}

  ngOnInit(): void {
    console.log(this.htmlContent);
    this.redirectUrl = sessionStorage.getItem('redirectUrl');
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.windowRef.location.href = this.redirectUrl;
  }
}

type DialogData = {
  data: string;
}