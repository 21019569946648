import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AutoLoginComponent } from './auto-login';
import { UnauthorizedComponent } from './unauthorized';
import { PageNotFoundComponent } from './page-not-found';
import { FailurePaymentComponent } from './failure-payment/failure-payment.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';

const routes: Routes = [
  {
    path: 'auto-login',
    component: AutoLoginComponent
  },
  {
    path: 'success-payment.html',
    component: SuccessPaymentComponent
  },
  {
    path: 'failure-payment.html',
    component: FailurePaymentComponent
  },
  {
    path: 'auto-login',
    component: AutoLoginComponent
  },
  {
    path: 'common',
    loadChildren: () => import('./common-components/common-components.module').then(m => m.CommonComponentsModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
