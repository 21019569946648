import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutoLoginComponent } from './auto-login/auto-login.component';

import { CoreModule } from './core/core.module';
import { MaterialModule } from './material.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorDialogComponent } from './auto-login/error-dialog/error-dialog.component';
import { DialogContentComponent } from './auto-login/dialog-content/dialog-content.component';
import { MatIconModule } from '@angular/material/icon';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { FailurePaymentComponent } from './failure-payment/failure-payment.component';

@NgModule({
  declarations: [
    AppComponent,
    AutoLoginComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    ErrorDialogComponent,
    DialogContentComponent,
    SuccessPaymentComponent,
    FailurePaymentComponent
  ],
  imports: [
    CoreModule,
    MaterialModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule
  ],
  providers: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
