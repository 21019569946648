import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/services';
import { WINDOW } from '@ng-web-apis/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent implements OnInit {
  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
  ) { }

  ngOnInit(): void {
  }

}
