import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { MerchantDetails } from 'src/app/shared/models';
import { GlobalConstants } from 'src/app/shared/constants';

@Injectable({ providedIn: 'root' })
export class CardService {

  constructor(private http: HttpClient) { }

  getMpgsSessionUrl(merchantId: string) {
    const mpgsScriptUrl = GlobalConstants.MPGS.BASE_URL + "form/version/" + GlobalConstants.MPGS.SANDBOX_MODULE_VERSION + "/merchant/" + merchantId + "/session.js?debug=true";
    return mpgsScriptUrl;
  }

  saveCreditCard(requestBody: any) {
    return this.http.post<any>(`${environment.apiUrl + 'open/mpgs/cards/submit'}`, requestBody);
  }

  generateCardExpiryYears() {
    let currentYear = new Date().getFullYear();
    const years: number[] = [];
    const limit = currentYear + 20;
    while (limit >= currentYear) {
      years.push(currentYear % 100);
      ++currentYear;
    }
    return years;
  }

  createMPGSSession(requestBody: any) {
    return this.http.post<any>(`${environment.apiUrl}mpgs/open/session?accessKey=${requestBody.accessKey}&payeeKey=${requestBody.payeeKey}`, {});
  }

  getthreedsMpgsSessionUrl(merchantId: string) {
    const mpgsScriptUrl = GlobalConstants.MPGS.BASE_URL + "form/version/" + GlobalConstants.MPGS.SANDBOX_MODULE_MPGS_VERSION + "/merchant/" + merchantId + "/session.js?debug=true";
    return mpgsScriptUrl;
  }

  saveThreeDSMPGSCreditCard(requestBody: any) {
    return this.http.post<any>(`${environment.apiUrl + 'open/mpgs/cards/submit'}`, requestBody);
  }
}
